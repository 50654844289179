import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import Loader from '../../../components/Loader';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';

import './style.scss';

const StoragecraftPage = () => {
    return (
        <Layout navTheme="light">
            <Seo title="StorageCraft" />
            <div className="storagecraft-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <p className="hero-subtext white-text">
                                Take it from StorageCraft
                            </p>
                            <h1 className="white-text">
                                This is what a great partner enablement program
                                looks like
                            </h1>
                        </Col>
                    </Row>
                    <Row className="center video-row">
                        <Col className="centered-text max-width-900">
                            <WistiaPopoverVideo id={'emfnqw87ws'}>
                                <img
                                    className="thumbnail"
                                    src={require('../../../images/reviews/storagecraft/thumbnail.png')}
                                    alt="StorageCraft video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../svgs/common/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="one-half">
                            <img
                                src={require('../../../images/reviews/storagecraft/interview.png')}
                                alt="StorageCraft during interview"
                            />
                        </Col>
                        <Col className="one-half">
                            <p>
                                StorageCraft Enterprise Sales Manager Chip Vasek
                                gives the inside scoop on deploying a
                                next-generation partner enablement program from
                                TRIdigital Marketing and GlassHive.
                            </p>
                            <p>
                                Chip is the main man when it comes to developing
                                strategies and programs that empower
                                StorageCraft’s VAR partner network. He came to
                                TRIdigital and GlassHive looking for a way to
                                deploy amazingly effective collateral with
                                management and visibility tools that kept a
                                finger on the pulse of every campaign’s
                                performance.
                            </p>
                            <p>
                                His partner enablement program through
                                TRIdigital and GlassHive delivered 2X BETTER
                                RESULTS than programs he’d run in the past.
                            </p>
                            <p>
                                Pretty incredible, right? Watch the full
                                testimonial to learn more about Chip’s
                                experience.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row className="flexed-mobile stretch">
                        <Col className="one-half centered-text rel z-index-9 small-width min-height-100">
                            <form
                                id="vendors-form"
                                className="pink-form"
                                method="POST"
                                name="Vendors Form"
                                action="/reviews/storagecraft/#thanks"
                                encType="multipart/form-data"
                                netlify="true"
                                netlify-honeypot="bot-field"
                                data-category="Vendor"
                                onSubmit={e => {
                                    e.preventDefault();
                                    const submitButton = document.getElementById(
                                        'sbmt-form-btn'
                                    );
                                    const loader = document.querySelector(
                                        '.loader'
                                    );
                                    const formName = document.getElementById(
                                        'vendors-form'
                                    );

                                    loader.style.display = 'block';
                                    submitButton.style.display = 'none';

                                    fetch(formName.getAttribute('action'), {
                                        method: 'POST',
                                        body: new FormData(
                                            document.getElementById(
                                                'vendors-form'
                                            )
                                        ),
                                    }).then(res => {
                                        if (res.status === 200) {
                                            document.querySelector(
                                                '#vendors-form'
                                            ).style.display = 'none';
                                            document.querySelector(
                                                '.contact-thank-you'
                                            ).style.display = 'block';
                                        } else {
                                            loader.style.display = 'none';
                                            document.getElementById(
                                                'error-msg'
                                            ).style.display = 'block';
                                            submitButton.style.display =
                                                'block';
                                        }
                                    });
                                }}
                            >
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="vendors-form"
                                />
                                <input
                                    type="hidden"
                                    name="bot-field"
                                    id="bot"
                                />
                                <h3 className="white-text form-title">
                                    Do you want the same fantastic experience
                                    for your organization? Reach out to a
                                    channel expert today!
                                </h3>
                                <div className="what-do-we-call-you">
                                    <div className="field name-field">
                                        <label htmlFor="firstName" hidden>
                                            What’s your name?
                                        </label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            placeholder="Name"
                                            required
                                        ></input>
                                    </div>
                                    <div className="field email-field">
                                        <label hidden htmlFor="email">
                                            What’s your email address?
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            required
                                        ></input>
                                    </div>
                                    <div className="field">
                                        <label
                                            className="second-label"
                                            hidden
                                            htmlFor="company"
                                        >
                                            What company are you with?
                                        </label>
                                        <input
                                            type="text"
                                            name="company"
                                            placeholder="Company"
                                            id="company"
                                        ></input>
                                    </div>
                                </div>

                                <div className="field comment-field">
                                    <label
                                        hidden
                                        className="textarea-label"
                                        htmlFor="message"
                                    >
                                        What question can we answer for you?
                                    </label>
                                    <textarea
                                        type="text"
                                        name="message"
                                        id="message"
                                        placeholder="Comments"
                                    ></textarea>
                                </div>
                                <div className="hide-me field">
                                    <input
                                        data-form-type="Consultation"
                                        className="formcat"
                                    ></input>
                                </div>
                                <div className="submit-btn">
                                    <Loader />
                                    <p id="error-msg">
                                        Looks like there was a problem
                                        submitting your form. Please ensure ALL
                                        form fields are filled out and try
                                        again.
                                    </p>
                                    <button
                                        id="sbmt-form-btn"
                                        type="submit"
                                        className="pink-button"
                                    >
                                        SEND
                                    </button>
                                </div>
                            </form>
                            <div
                                className="contact-thank-you reach"
                                id="thanks"
                            >
                                <h5>
                                    Thank you for contacting us. We'll be in
                                    touch shortly!
                                </h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default StoragecraftPage;
